import React from "react";
import "./About.css";
import Me from "../../assets/manis1.png";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { GrProjects } from "react-icons/gr";
import { VscFolderLibrary } from "react-icons/vsc";
const About = () => {
  return (
    <section id="about">
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={Me} alt="about Image" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>1 year Working</small>
            </article>

            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Clients</h5>
              <small>1+</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>1+ completed</small>
            </article>
          </div>
          <p>
            A frontend developer in the learning phase who is
            innovative, task-driven professional, passionate about creating
            interactive applications and exploring other technologies that catch
            my interest. I'm excited to leap and continue refining my skills
            with the right company.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
