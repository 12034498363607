import React from "react";
import "./Portfolio.css";
import IMG1 from "../../assets/linkedin.png";
import IMG2 from "../../assets/contactbook.png";
import IMG3 from "../../assets/fbclone.png";
import IMG4 from "../../assets/todolist.png";
import IMG5 from "../../assets/traveldestination.png";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "LinkedIn Clone",
    github: "https://github.com/RKarkee/linkedin-Clone-React",
    demo: "https://linkedin-clone-2ed6f.web.app/",
  },
  {
    id: 2,
    image: IMG2,
    title: "Contact Book  ",
    github: "https://github.com/RKarkee/React-Redux-Contacts-Book-App",
    demo: "https://react-redux-contacts-book-app.vercel.app/",
  },
  {
    id: 3,
    image: IMG3,
    title: "Social Media(Facebook) Clone",
    github: "https://github.com/RKarkee/Tutorial-MUI-React-App",
    demo: "https://tutorial-mui-react-app.vercel.app/",
  },
  {
    id: 4,
    image: IMG4,
    title: "Todos List",
    github: "https://github.com/RKarkee/Todos-List-React",
    demo: "https://todos-list-react-rkarkee.vercel.app/",
  },
  {
    id: 5,
    image: IMG5,
    title: "Travel Destination ",
    github: "https://github.com/RKarkee/linkedin-Clone-React",
    demo: "https://modest-bohr-4bc2aa.netlify.app/",
  },
  // {
  //   id: 6,
  //   image: IMG1,
  //   title: "LinkedIn Clone",
  //   github: "https://github.com/RKarkee/linkedin-Clone-React",
  //   demo: "https://linkedin-clone-2ed6f.web.app/",
  // },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfolio__items">
              <div className="portfolio__items-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} className="btn" target="_blank">
                  GitHub
                </a>
                <a href={demo} className=" btn btn-primary" target="_blank">
                  Live Demo
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
