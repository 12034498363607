import React from "react";
import { BsLinkedin, BsGithub, BsFacebook } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
// import {BsFacebook} from 'react-icons/bs'
const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://www.linkedin.com/in/manish-karki-6a5135207" target="_blank">
        <BsLinkedin />
      </a>
      <a href="https://github.com/RKarkee" target="_blank">
        <BsGithub />
      </a>
      <a href="https://www.facebook.com/profile.php?id=100008140059918" target="_blank">
        <BsFacebook />
      </a>
      {/* <a href="https://gmail.com" target="_blank">
        <GrMail />
      </a> */}
    </div>
  );
};

export default HeaderSocials;
